import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo, FAQJsonLd  } from 'gatsby-plugin-next-seo';
export default ({ data }) => {
  const { page } = data
  const { title, content, databaseId, seo, slug } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.kasinot365.com/${slug}/`}
      />
      {title === "Pay N Play Casino" &&
        <FAQJsonLd
        questions={[
          { question: 'Mikä on Pay N Play Casino?', answer: 'Pay N Play -teknologiaa hyödyntävä kasino mahdollistaa asiakkailleen rekisteröitymisvapaan pelikokemuksen. Asiakkaan tunnistautuminen tapahtuu ensitalletuksen yhteydessä verkkopankkitunnusten avulla.' },
          { question: 'Onko Pay N Play kasinoilla pelaaminen turvallista?', answer: 'Pay N Play avulla suoritettava pelaaminen on tämän hetken turvallisin vaihtoehto harrastaa rahapelaamista verkossa. Asiakastietoja ei tarvitse jättää operaattorille lainkaan.' },
          { question: 'Kuinka nopeat kotiutukset Pay N Play casinoilla on?', answer: 'Pay N Play kasinot tarjoavat rahapelialan nopeimmat voittojen nostot. Kotiutusajat ovat kasinokohtaisia ja vaihtelevat muutamasta sekunnista 15 minuuttiin. Lue sivuiltamme lisätiedot nopeimmista kotiuttajista.' },
          { question: 'Mitä kasinopelejä voin pelata Pay N Play kasinoilla?', answer: 'Kasinot jotka hyödyntävät Pay N Playta tarjoavat pelattavaksesi kaikki normaalit pöytäpelit, suosituimmat slotit ja arvontapelit jotka löydät perinteisiltäkin nettikasinoilta.' },
          { question: 'Tuleeko Pay N Play talletuksesta lisäkuluja pelaajalle?', answer: 'Pay N Playn käyttäminen on pelaajalle aina ilmaista. Järjestelmästä koituvat lisäkulut maksaa aina operaattori.' },
          { question: 'Kenen kehittämä Pay N Play -teknologia on?', answer: 'Pay N Playn alkuperäinen kehittäjä on ruotsalainen maksuliikennevälittäjä Trustly AB. Trustlyn menestyneen tuotteen jälkeen useat muut maksunvälittäjät ovat alkaneet kehitellä samantapaisia järjestelmiä.' },
          { question: 'Mitä suomalaisia pankkeja Pay N Play tukee?', answer: 'Voit pelata Pay N Playn avulla mikäli sinulta löytyy jonkun seuraavan suomalaisen pankin verkkopankkitunnukset: Osuuspankki (OP), Nordea, S-Pankki, Danske Bank, Säästöpankki, POP Pankki, OmaSP, Aktia, Handelsbanken tai Ålandsbanken.' },
        ]}
      />
      }
      <article
        className={`post-${databaseId} post page type-page status-publish hentry testi`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </header>
        <div className="post-inner thin container">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
